
.col-xl-4{
	@include transition;
}

@media screen and (min-width: 1200px){
	.offer-items:hover .offer__element {
		width: 32.5%;
	}
	.offer-items .offer__element:hover{
		width: 34.8%;
	}
}

.our-offer{
	position: relative;
	& .container-fluid{
		padding-left: 0;
		padding-right: 0;
	}
	& .col-xl-4{
		padding-left: 0;
		padding-right: 0;
	}
	& .col-12{
		@media screen and (max-width: 823px){
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}
 & .offer, & .offer--secondary, & .offer--first{
	width: 100%;
	height: auto;
	background-position: center ;
	position: relative;
	z-index: 100;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 4rem 1.5rem;
	background-size: cover;
	@include media-breakpoint-up(lg){
		padding: 4.5rem 4rem;
		height: 100%;
	}
	@include media-breakpoint-up(xl){
		padding: 15.5rem 8rem;
		width: 100%;
		height: auto;
		max-height: 66rem;
	}
	@media screen and (min-width:1280px) and (max-width: 1600px){
		padding: 7rem 3rem;
		height: 100%;
	}

	h3{
		margin-top: 0;
	}
	&__title{
		color: $white;
		font-size: 1.7rem;
		letter-spacing: 0.07em;
		line-height: 2.1rem;
		font-weight: 700;
		margin-bottom: 3rem;
		@include media-breakpoint-up(xl){
			font-size: 2.8rem;
			letter-spacing: 0.05em;
			line-height: 3rem;
			margin-bottom: 4rem;
		}
		@media screen and (min-width:1280px) and (max-width: 1600px){
			font-size: 2.5rem;
		}
	}
	&::after{
		content: '';
		background-color: rgba(0, 51, 86, 0.50);
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;

	}
	& p{
		font-size: 1.4rem;
		letter-spacing: 0.07em;
		line-height: 2.1rem;
		font-weight: 400;
		color: $white;
		margin-bottom: 4rem;
		@include media-breakpoint-up(lg){
			min-height: 18.9rem;
		}
		@include media-breakpoint-up(xl){
			font-size: 1.5rem;
			line-height: 2.6rem;
			margin-bottom: 4rem;
			min-height: 13rem;
		}
		@media screen and (min-width:1280px) and (max-width: 1600px){
			margin-bottom:4rem;
			display: -webkit-box;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
			overflow: hidden;
			min-height: 15.6rem;
		}
	}
 }
& .btn{

	@include media-breakpoint-up(xl){
		margin: 4rem 0 4rem 0;
	}
	@media screen and (min-width:1280px) and (max-width: 1600px){
		margin: 0 0 2rem 0;
	}
 }
}
