
.logo-mobile{
	padding: 1.3rem 1.5rem;
}
.logo-mobile{
	a.mobile-nav-link{
		font-size: 0;
		line-height: 0;
	}
}

