@mixin make-container($gutter: $grid-gutter-width) {
	width: 100%;
	padding-right: $gutter / 2;
	padding-left: $gutter / 2;
	margin-right: auto;
	margin-left: auto;
}

@mixin make-grid-containers() {
	@each $breakpoint, $container-max-width in $container-max-widths {
		.container-#{$breakpoint} {
		  @extend .container-fluid;
		}

		@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
		  %responsive-container-#{$breakpoint} {
			max-width: $container-max-width;
		  }

		  $extend-breakpoint: true;

		  @each $name, $width in $grid-breakpoints {
			@if ($extend-breakpoint) {
			  .container#{breakpoint-infix($name, $grid-breakpoints)} {
				@extend %responsive-container-#{$breakpoint};
			  }

			  @if ($breakpoint == $name) {
				$extend-breakpoint: false;
			  }
			}
		  }
		}
	}
}
