body{
	padding-bottom: 0 !important;
}
.intro-section{
	position: relative;
	padding-bottom: 4rem;

	@include media-breakpoint-up(xl){
		padding-bottom: 8rem;
	}
	@media (min-width: 1600px){
		padding-bottom: 13rem;
	}
& .section__heading{
	margin-top: 4rem;
	@include media-breakpoint-up(xl){
		margin-top: 0;
	}
}
&__description{
	font-weight: 500;
	& strong{
		font-weight: 900;
		@include media-breakpoint-up(lg){
			font-weight: 500;
		}
	}
}


	.right-side-box{
		right: -67vw;
		bottom: -5rem;
		@include media-breakpoint-down(lg){
			margin-top: 0;
			bottom: 14.8rem;
			position: absolute;
			right: -60rem;
		}
		@include media-breakpoint-down(md){
			margin-top: 0;
			bottom: 14.8rem;
			position: absolute;
			right: -48rem;
		}
		@include media-breakpoint-down(sm){
			margin-left: 1.5rem;
			margin-top: 0;
			position: static;
			width: 500px;
		}

		@media screen and (max-width:736px) and (orientation: landscape){
			margin-left: 20rem;
		}
		@media screen and (max-width:640px) and (orientation: landscape){
			margin-left: 15rem;
		}
		@media screen and (max-width:480px) and (orientation: landscape){
			margin-left: 10rem;
		}
		@media screen and (min-width:1280px) and (max-width: 1600px){
			right: -63vw;
			bottom: -4.2rem;
		}
		@media screen and (min-width:1921px){
			right: -83vw;
			bottom: 100%;
		}
		&__inner__logo{
			@media screen and (max-width:320px){
				left: 15%;
				transform: skew(-13deg);
				width: 40%;
			}
			@media screen and (min-width:360px) and (max-width: 414px){
				width: 40%;
				left: 20%;
				transform: skew(-13deg);
			}

			@include media-breakpoint-up(md){
				transform: skew(-13deg);
				left: 30%;
			}

			@media screen and (max-width:736px) and (orientation: landscape){
				transform: skew(-13deg);
				left: 30%;
				width: 50%;
			}

			.side-box-logo--mob{
				transform: skew(13deg);
				@include media-breakpoint-up(md){
					display: none;
				}
			}
			.side-box-logo--desk{
				display: none;
				@include media-breakpoint-up(md){
					transform: skew(13deg);
					display: block;
					width: 90%;
				}
				// @include media-breakpoint-up(xl){
				// 	width: 55%;
				// }
				// @include media-breakpoint-up(xxl){
				// 	width: 40%;
				// }
			}
		}
		&__inner-bg .bg-container img {
			@media screen and (min-width:320px) and (max-width: 414px){
				width: 100%;

			}
		}
	}


	.intro-section__slogan{
		margin-top: 3.5rem;
		margin-bottom: 3.5rem;

		@include media-breakpoint-up(lg){
			margin-top: 11.6rem;
			margin-bottom: 0;
		}

		@media screen and (min-width:1024px) and (max-width: 1599px){
			margin-top: 3.6rem;
		}

		p{
			font-size: 2rem;
			font-weight: 300;
			letter-spacing: 0.073em;
			line-height: 2.4rem;
			text-align: center;
			margin: 2rem 0;
			@include media-breakpoint-up(lg){
				font-size: 2.8rem;
				line-height: 3rem;
				margin: 3.5rem 0;
			}
		}
		&::before, &::after{
			content: '';
			display: block;
			width: 16.2rem;
			height: 1px;
			background-color: #070707;
			margin: 0 auto;
			@include media-breakpoint-up(lg){
				width: 21.2rem;
			}
		}
	}
	& br{
		@include media-breakpoint-up(lg){
			display: none;
		}
	}
}
body > main {
	padding: 0 0 4rem 0;
}

