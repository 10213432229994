@media (prefers-reduced-motion: reduce) {
	.form-control {
		transition: none;
	}
}


.valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #28a745;
}


.invalid-feedback {
	display: none;
	width: 100%;
	font-size: 80%;
	color: #dc3545;
}


.acym_module_form {
	.d-none {
		display: none;
	}
}

#newsletter-code-5, #newsletter-code-3{
	display: flex;
	p{
		margin-left: 1rem;
	}
}
.loading{
	left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.9);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 1;
	transition: .3s opacity ease-in-out;

	p{
		font-size: 3rem;
		font-weight: bolder;
		letter-spacing: .1em;
	}

	.dots{
		width: 60px;
		height: 60px;
		display: flex;
		flex-direction: row;
		padding-top: 30px;
		.dot{
			margin: 0 4px;
			height:12px;
			width:12px;
			background: #28afb0;
			border-radius: 50%;
			animation: bouncing .3s ease-in alternate infinite;
		}

		.dot:nth-of-type(2){
			background:#f4d35e;
			animation-delay: .1s;
		}
		.dot:nth-of-type(3){
			animation-delay: .2s;
			background: #ee964b;
		}
	}

	@keyframes bouncing{
		0%{
			transform: translateY(-15px);
		}
		100%{
			transform: translateY(0px);
		}
	}
}

.close{
	display: none;
	opacity: 0;
}
