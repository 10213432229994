#newsletter{
	background-image: url('../../../images/strona-glowna/newsletter/newsletter-tlo-mob.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center ;
	#formAcym47181{
		margin-bottom: 0;
	}
	#formAcym81391{
		margin-bottom: 0;
	}
	#formAcym25071{
		margin-bottom: 0;
	}
	@include media-breakpoint-up(lg){
		background-image: url('../../../images/strona-glowna/newsletter/newsletter-tlo.jpg');
		padding-bottom: 8rem;
	}
	.section__heading{
		margin-top: 0;
		@include media-breakpoint-up(lg){
			margin-top: 0rem;
		}
	}
}
.newsletter-form{
	background-image: url();
	.form-group{
		padding-bottom: 0;
		margin-bottom: 2.5rem;
	}
	.rodo-container{
		display: flex;
		flex-direction: column;
		position: relative;
		& input[type="checkbox"]{
			width: 17px;
			height: 17px;
			align-self: center;
			position: absolute;
			top: 10%;
			left: 0;
			border-radius: 0px;
			// border: 1px solid rgba(0, 108, 181, .2) !important;
		}
		label{
			margin-left: 2.5rem;
			font-weight: 500;
		}
	}
	& p{
		margin: 0 0 1.1rem;
		font-weight: 700;
	}
	& label{
		@include media-breakpoint-down(md){
			font-weight: 600;
		display: block;
		margin-bottom: 0.5rem;

		}

	}
	label[for="terms"]{
		@include media-breakpoint-down(md){
			margin-bottom: 0;
		}
		@media (max-width: 360px){
			letter-spacing: 0.05em;
		}
	}
	.form-input{
		p{
			display: inline;
			&::after{
				content: '*';
			}
		}
	}
	& .btn{
		margin: 0rem auto 0rem ;
		display: block;
		padding: 2rem 3rem;
		@include media-breakpoint-up(lg){
			margin: 1rem 0 0 0;
			padding: 2.6rem 6.5rem;
		}
	}

}
.red-star{
	color: red;
}
