.container,
.container-fluid {
  @include make-container();
}

.row {
	@include make-row();
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;

	> .col,
	> [class*='col-'] {
		padding-right: 0;
		padding-left: 0;
	}
}

@include make-grid-columns();
@include make-grid-containers();
