body.serwis{
	h1{
		@include media-breakpoint-down(xl){
			margin-top: 2rem;
		}
	}
	.service__box{

		position: relative;
		img{
			width:100%;
			border-radius: 14px;
			cursor: pointer;
			object-fit: cover;
				&::before{
					content: url(../../../images/strona-glowna/fullsize-img.svg);
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					position: absolute;
					display: flex;
					align-items: center;
					justify-content: center;
					pointer-events: none;
					z-index: 2;
					opacity: 0;
					transition: .3s all;
				}
				&::after{
					content: "";
					display: flex;
					align-items: center;
					justify-content: center;
					pointer-events: none;
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					background-color: rgba(0, 51, 86, 0.7);
					z-index: 1;
					transform: translateY(100%);
					transition: all 0.3s ease 0s;
				}
			}


		&__list{
			list-style-type: none;
			position: relative;
			li{
				margin-bottom: 1.5rem;
				font-size: 1.5rem;
				&::before{
					content:url('../../../images/urzadzenia-magnetyczne/serwis/wrench.svg');
					position: absolute;
					left:0;
				}
			}
		}

		p{
			margin-bottom: 1.5rem;
			line-height: 2rem;
			&:last-of-type{
				margin: 4rem 0 0 0;
				color: $blue;
				font-weight: bolder;
				font-size: 2rem;
			}
			&:first-of-type{
				margin-top: 1.5rem;

			}
		}
		&--offer{
			display: flex;
			flex-direction: column;
			align-items: center;
			h3{
				text-transform: uppercase;
				color: $blue;
				font-weight: bold;
				@include media-breakpoint-down(md){
					margin: 2rem 0;
					text-align: center;
				}
			}
		}
		.gallery__item{
			height:auto;
		}
		&--gallery{
			display: flex;
			align-items: center;
			@media (max-width:767.98px){
				flex-direction: column;
			}
		}
	}

	.separator{
		width: auto;
		height: 100%;
		display: flex;
		align-items: center;
		margin: 0 5rem;
		@media (max-width:767.98px){
			transform: rotate(90deg);
			margin: 2rem 0;
		}
	}
}
