.tab {
	overflow: hidden;
	display: flex;
	width: 100%;
	justify-content: center;
	margin: 3rem 0 2rem;
}

.tab strong {
	font-size: 1.5rem;
	font-weight: bold;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	line-height: 1.8rem;
	padding-bottom: 1.6rem;
	position: relative;
	color: $black;
	cursor: pointer;
	width: 50%;
	text-align: center;

	&::after {
		content: '';
		position: absolute;
		bottom: 1px;
		left: 0;
		width: 100%;
		height: 3px;
		background-color: $blue;
		opacity: 0.1;
		@include transition();
	}

	&:hover,
	&.active {
		&::after {

			opacity: 1;
		}
	}

}
strong.active{
	color: $blue;
}
.tabContent {
	display: none;
	border-top: none;
	min-height: auto;
	&.open {
		display: block;
		animation-name: showTabcontent;
		animation-duration: .6s;
	}
}

@keyframes showTabcontent {
	from {opacity: 0}
	to {opacity: 1;}
}


svg.MuiSvgIcon-root {
	fill: white !important;
}

.tablinks {
	font-size: 1.4rem;
	font-weight: bold;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	line-height: 1.9rem;
}
