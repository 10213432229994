
.references{

    .references-items{
        margin-bottom: -5rem;
    }
    .reference{
      margin-bottom: 5rem ;

        .ref-card{
            padding: 2rem 1rem;
            display: flex;
            flex-direction: column;
			position: relative;
            align-items: center;
            border-radius: 8px;
            height: 100%;
            background-color: $light;
            position: relative;
            z-index: 100;
			@include media-breakpoint-up(lg){
				flex-direction: row;
			}
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: transparent;
                @include transition();
                border-radius: 8px;
                z-index: 1;
                top: 0;
                left: 0;
                pointer-events: none;
            }
            &:hover{
                @include media-breakpoint-up(lg){
                    &::after{
                        background-color: rgba($primary,0.1);
                        z-index: 1;
                    }
                }
				svg{
					fill: $primary;
				}
            }
           & img{

                display: block;
                height: auto;
                margin: 0 auto;
                width: 60%;
                cursor: pointer;
                @include media-breakpoint-up(md){
                    width: 50%;

                }
            }
            & p{
                text-align: center;
                margin-top: 0.5rem;

                line-height: 2rem;
				@include media-breakpoint-up(lg){
					text-align: left;
				}
            }
            &__title{

                padding: 1rem 2rem;
@include media-breakpoint-up(lg){
padding: 1rem 2rem 1rem 0;
}
                h3{
                    margin: 0;
                    color: $primary;
                    text-align: center;

					@include media-breakpoint-up(lg){
						text-align: left;
					}

                }
            }
			svg{
				width: 7rem;
				height: 7rem;
				position: absolute;
				top: -2.5rem;
				right: -1.5rem;
				@include media-breakpoint-up(xl){
					width: 9rem;
					height: 9rem;
					top: -3rem;
				    right: -3rem;
				}
			}
        }
    }
}
