.control-units{
	img{
		width: 100%;
		height: auto;
		@include media-breakpoint-down(md){
			margin-top: 3rem;
		}
		@media (max-width: 822px) and (orientation: landscape){
			width: 60%;
			margin: 3rem auto 0 auto;
			display: block;
		}
	}

	.box{
		margin: 4rem 0;
		@include media-breakpoint-up(lg){
			margin-top: 4rem ;
			margin-bottom: 0;
		}

	}
	ul{
		list-style: none;
		padding-left: 5rem;
		@include media-breakpoint-up(lg){
			padding-left: 4rem;
		}
		li{
			margin-bottom: 1.5rem;
			font-size: 1.5rem;
			&::before{
				content:url('../../../images/urzadzenia-magnetyczne/akcesoria-magnetyczne/controls.svg');
				position: absolute;
				left:1.5rem;

			}
		}
	}
}
