.carousel--dark {
	.carousel-control-prev,
	.carousel-control-next {
		color: black;
	}
	.carousel-indicators {
		li {
			background-color: rgba(black, 0.5);
		}

		.active {
			background-color: rgba(black, 1);
		}
	}

}

.slider-arrow {
    display: none;
    text-align: center;
    position: absolute;
    bottom: 0;
    z-index: 99;
    left: 0;
    width: 100%;
	svg {
		fill:white;
		width:3rem;
	}
	@include media-breakpoint-up(lg){
		display: block;
	}
}
