body.toczenie-wroclaw{
	#content{
		.turning{
			&__img{
					position: absolute;
					top:11rem;
					right: 1.5rem;

					@media (min-width:1100px) and (max-width:1599px){
						 width:40%;
						 display: block;
					}


					img {
						display: none;
						@include media-breakpoint-up(xl){
							display: block;
							border-radius: $border-radius;

						}
					}

			}

			&__list{
				list-style-type: none;
				li{
					position: relative;
						padding: 1rem 0;
						font-size: 1.5rem;
						line-height: 2.3rem;
					&::before{
						content:url(../../../images/obrobka-skrawaniem/toczenie/cnc-machine.svg);
						position: absolute;
						left:-32px;
					}
				}
			}
			&__description{
				margin-bottom: 2rem;
			}
			&__video{
				&__section{
					margin-top: 8rem;
					@include media-breakpoint-down(lg){
						margin-top: 0rem;
						margin-bottom: 2rem;
					}
					@include media-breakpoint-down(sm){
						flex-direction: column-reverse;
						margin-top: 0rem;
						margin-bottom: 2rem;
					}
				}
			}
		}
		h3{
			color: $blue;
			font-weight: bold;
		}
		video{
			width:100%;
			padding:20px;
			border: 4px solid $light;
			max-height: 500px;
		}
	}

}
