form{
	label{
		font-size: 1.4rem;
		line-height: 2.1rem;
		letter-spacing: 0.07em;
		font-weight: 700;
		@include media-breakpoint-up(xl){
			font-size: 1.5rem;
			font-weight: 400;
			line-height: 2.6rem;
			padding-bottom: .5rem;
			padding-top: .5rem;
			display: block;
		}
		span.star{
			color: red;
		}
	}
	label[for="rodo"]{
		margin-left: 2.5rem;
	}
	input,select,textarea{
		border: 1px solid rgba(0, 108, 181, 0.2);
		border-bottom: 3px solid $blue;
		width: 100%;
		padding: 1.8rem 2rem;
		&:focus{
			outline: none;
		}
		&::placeholder{
			font-size: 1.4rem;
			font-style: italic;
			letter-spacing: 0.07em;
			line-height: 2.1rem;
			opacity: 0.4;
			@include media-breakpoint-up(xl){
				font-size: 1.5rem;
				line-height: 2.6rem;
			}
		}
	}
	input[type="checkbox"]{
	width: 17px;
    height: 17px;
    border-radius: 0px;
    border: 1px solid rgba(0,108,181,.2) !important;
	position: absolute;
	left:0;
	top:10px;
	@include media-breakpoint-down(xl){
		top:0;
	}
	}
	input[type="submit"]{
		cursor: pointer;
		margin-top: 3rem;
	}


	.select-box{
		position: relative;
		overflow: hidden;
		width: 100%;
		&::before,
		&::after {
			content: '';
			height: .2rem;
			width: 1rem;
			background-color: $primary;
			position: absolute;
			top: calc(50% - 0.1rem);
			right: 2.2rem;
			z-index: 1;
		}
		&::before {
			transform: translateX(-.3rem) rotate(45deg);
		}
		&::after {
			transform: translateX(.3rem) rotate(-45deg);
		}
		& select{
			appearance: none;
			overflow: hidden;
			display: block;
		}
	}
	.form-group{
		position: relative;
		@include media-breakpoint-down(xl){
			margin-bottom: 1rem;
		}
	}
}





form {


	.invalid-feedback {
		margin-bottom: 0rem;
		margin-top: 5px;
		@include media-breakpoint-down(md){
			line-height: 1.2rem;
		}
	}
}

#booking form {
	margin-top: 6rem;
}

.v-visible {
	visibility: visible !important;
}

.invalid-feedback {
	visibility: hidden;
	display: block !important;
}

.fileinfo {
	font-size: 1.2rem;
}


#contact169 {
	cursor: text;
	@include media-breakpoint-down(lg) {
		& .form-courses__navigation {
			flex-direction: row;
			& .btn{
				margin-bottom: 0;
				margin-right: 1.5rem;
			}
			@media (orientation:portrait) {
				margin-top: 3rem !important;
				margin-bottom: 1rem;
			}
			@media (orientation:landscape) {
				margin-top: 2rem !important;
				margin-bottom: 1rem;
			}

		}
		&:focus &:active{
			outline: none;
			box-shadow: none;
		}
	}
}



