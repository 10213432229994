.our-stats{

	.btn-stats{
		display: flex;
		justify-content: center;
		& .btn{
			display: inline-block;
			margin-top: 2rem;
			@include media-breakpoint-up(md){
				margin-top: 3rem;
			}
			@include media-breakpoint-up(lg){
				margin: 6rem auto 0;
			}

		}
	}
	padding-bottom: 4rem;
	@include media-breakpoint-up(xl){
		padding-bottom: 8rem;
	}
	.col-md-6{
		margin: 0 auto;
	}
}
.stat__element:nth-of-type(2) .stat span.stat__number {
	font-size: 8.5rem;
	@include media-breakpoint-down(lg){
		font-size: 6.3rem;
	}
}
.stat{
	height: 17.6rem;
	max-height: 100%;
	position: relative;
	z-index: 100;
	border-radius: $border-radius;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 5rem 2rem;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	margin-bottom: 2rem;

	@include media-breakpoint-up(xl){
		padding: 8rem 3rem;
		height: 24rem;
		margin-bottom: 0rem;
		@include transition;
	}
	@media screen and (min-width:1280px) and (max-width: 1600px){
		padding: 8rem 1rem;
	}

	&::after{
		content: '';
		background-color: rgba(0, 51, 86, 0.75);
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: $border-radius;
	}
	&__number{
		letter-spacing: 0.05em;
		font-size: 4.4rem;
		line-height: 2.2rem;
		color: $white;
		font-weight: 900;
		position: relative;
		@include media-breakpoint-up(lg){
			font-size: 3.7rem;
		}
		@media screen and (min-width:1200px) and (max-width: 1366px){
			margin-left: 0rem;
			font-size: 4.4rem;
			line-height: 3rem;
			margin-right: 1rem;
		}
		@media screen and (width:1600px){
			margin-left: 3rem;
			font-size: 4.4rem;
			line-height: 3rem;
			margin-right: 2rem;
		}

		@media screen and (min-width:1601px){
			margin-right: 2rem;
			font-size: 6rem;
		}

		&::before{
				content: '';
				width: 21.6rem;
				height: 2px;
				background-color: $white;
				position: absolute;
				top: 2rem;
				left: 115%;
				display: block;
				opacity: 0;
				@media screen and (min-width:1280px) and (max-width: 1599px){
					left: 120%;
					width: 20rem;
				}
			@include media-breakpoint-down(lg){
				display: none;
			}
		}
	}
	&__description{
		@include transition;
		position: relative;
		p{
			font-size: 2rem;
			line-height: 2.1rem;
			letter-spacing: 0.05em;
			font-weight: 400;
			color: $white;
			margin: 0 0 0 2rem;
			position: relative;
			@include media-breakpoint-up(md){
				font-size: 2.3rem;
			}
			@include media-breakpoint-up(xl){
				font-size: 2.8rem;
				line-height: 3rem;
				margin: 0;
				opacity: 1;
				@include transition;
			}

		}
		&::after{
			content: url(../../../images/strona-glowna/corona.svg);
			position: absolute;
			left: 50%;
			transform: translate(-50%, -100%);
			-webkit-transform: translate(-50%, -100%);
			opacity: 0;
			@include transition;
			@include media-breakpoint-down(lg){
				display: none;
			}
		}

	}

&:hover{
	@include media-breakpoint-up(xl){
		.stat__number{
			&::before{
				opacity: 1;
				@include transition;
				transition-delay: 0.2s;
			}
		}
		.stat__description{
			transform: translateY(75%);
			-webkit-transform: translateY(75%);

			&::after{
				transform: translate(-50%, -235%);
				-webkit-transform: translate(-50%, -235%);
				opacity: 1;
			}
		}
	}
}
}
@include media-breakpoint-up(lg){
	.stat__element:nth-of-type(2) .stat .stat__number::before {
		width: 25.6rem;
		@media screen and (min-width:1280px) and (max-width: 1599px){
			width: 20rem;
		}
	}
	.stat__element:nth-of-type(2) .stat:hover .stat__description::after {
		transform: translate(-50%, -250%);
		-webkit-transform: translate(-50%, -250%);
	}
}


.stat__element:nth-of-type(3) .stat .stat__description::after {
	content: url(../../../images/strona-glowna/vehicle.svg);
}
.stat__element:nth-of-type(4) .stat .stat__description::after {
	content: url(../../../images/strona-glowna/people.svg);
}

