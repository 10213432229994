.white-element-banner {
	width: 100%;
	height: 100%;
	padding-bottom: 4rem;

}

#breadcrumbs {
	padding-top: 15rem;
	background-image: url('../../../images/breadcrumb/maszyna-do-laserowego-ciecia.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 80% 64%;
}

.breadcrumbs {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-size: cover;
	object-fit: contain;
	position: relative;
	height: 49.6rem;
	top: 0;

	@include media-breakpoint-down(lg) {
		height: 25.6rem;
	}

	@include media-breakpoint-up(xl) {
		top: 3px;
	}

	@include media-breakpoint-up(xxl) {
		top: 0;
	}

	overflow: hidden;

	&::after {
		content: '';
		height: 100%;
		width: 100%;
		background: linear-gradient(90deg, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 100%);
		display: block;
	}

	&__title {
		// margin-top: 16.6rem;
		display: block;
		position: absolute;
		// top: 20rem;
		top: 15rem;
		left: 15px;

		@include media-breakpoint-down(lg) {
			top: 10rem;
		}

		& h2 {
			font-size: 5.9rem;
			font-weight: 900;
			letter-spacing: 0.09em;
			line-height: 7.1rem;
			text-transform: uppercase;
			color: $white;

			@include media-breakpoint-down(lg) {
				font-size: 3.1rem;
				line-height: 3.8rem;
			}
		}
	}


	//
	.bottom-element {
		@include media-breakpoint-up(xl) {
			height: 13.6rem;
			background-color: $blue;
			position: absolute;
			right: -41.5rem;
			width: 133.4%;
			z-index: 9999;
			top: 36rem;
			transform: skewX(-40deg);
			border-radius: 5px 0 0 0;

			& .slider-contact-number-desktop {
				transform: skewX(40deg);
				bottom: 100%;
				width: 99rem;
				height: 13.6rem;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;

				& a {
					font-size: 4.1rem;
					font-weight: 900;
					line-height: 3.4rem;
					letter-spacing: 0.02em;
					text-decoration: none;
					@include transition;

					&:hover {
						text-decoration: underline;
						opacity: 1;
					}
				}

				& p {
					color: $white;
					align-self: flex-start;

					& strong {
						font-weight: 900;
					}

					margin-left: 6rem;
				}
			}

			& .white-element {
				height: 7.8rem;
				width: 99%;
				background-color: $white;
				position: absolute;
				bottom: 0;
				right: 0;
				border-radius: 5px 0 0 0;

				// position: relative;
				&::before {
					content: '';
					display: block;
					position: absolute;
					height: 1.1rem;
					width: 100%;
					background-color: $blue;
					left: -100%;
					bottom: 0;
				}
			}

			&__content {
				transform: skewX(40deg);
				display: flex;
				flex-direction: row;
				// width: 80rem;
				width: 45%;
				justify-content: space-between;
				position: relative;
				// margin-left: 3rem;
				padding: 1.5rem 0 1.5rem 4rem;

				@media screen and (min-width:1600px) and (max-width: 1919px) {
					width: 40%;
				}

				& .socials {


					& a {
						&:nth-child(1) {
							margin-right: 2rem;
						}
					}

				}

				& p {
					color: $white;

					@media screen and (min-width:1280px) and (max-width: 1599px) {
						display: none;
					}

					&::before {
						content: '';
						display: block;
						width: 45%;
						height: 1px;
						background-color: $white;
						position: absolute;
						top: 50%;
						right: 9rem;

						@media screen and (min-width:1600px) and (max-width: 1919px) {
							width: 27%;
						}
					}
				}
			}
		}
	}

	.slider-contact-number,
	.slider-contact-number-desktop {
		position: absolute;
		bottom: -21rem;
		right: -10rem;
		background-color: $primary;
		padding: 1.3rem 0;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		z-index: 999;

		@include media-breakpoint-up(lg) {
			bottom: -20.5rem;
		}

		@media screen and (max-width: 360px) and (orientation: portrait) {
			bottom: -21.1rem;
			right: -7rem;
		}

		@media screen and (max-width: 320px) and (orientation: portrait) {
			bottom: -19rem;
			right: -6rem;
		}

		& p {
			@include media-breakpoint-down(lg) {
				display: none;
			}
		}

		& a {
			font-size: 2.4rem;
			font-weight: 900;
			letter-spacing: 0.0031em;
			line-height: 2.1rem;
			color: $white;
			margin-right: 11.6rem;
			display: flex;
			align-content: center;
			align-items: center;


			@media screen and (max-width: 414px) and (orientation: portrait) {
				margin-right: 12rem;
			}

			@media screen and (max-width: 360px) and (orientation: portrait) {
				margin-right: 7.9rem;
			}

			@media screen and (max-width:667px) and (orientation: landscape) {
				margin-right: 11.5rem;
			}

			@media screen and (max-width: 568px) and (orientation: landscape) {
				margin-right: 12.5rem;
			}

			@media screen and (width: 768px) and (orientation: portrait) {
				margin-right: 11.6rem;
			}

			@media screen and (width: 800px) and (orientation: portrait) {
				margin-right: 5.6rem;
			}

			@media screen and (mid-width: 824px) and (width: 1024px) and (orientation: landscape) {
				margin-right: 11.6rem;
			}

			@media screen and (min-width:668px) and (max-width: 823px) and (orientation: landscape) {
				margin-right: 11.6rem;
			}

			&::before {
				content: url('../../../images/modules/phone-call.svg');
				margin-right: 2rem;

				@media screen and (max-width: 360px) and (orientation: portrait) {
					margin-right: 1rem;
				}
			}
		}

		&::before {
			content: "";
			position: absolute;
			right: 11%;
			width: 100%;
			top: 0;
			height: 100%;
			background-color: #00558e;
			transform: skewX(-40deg);
			border-radius: 5px 0 0 0;
			z-index: -1;
		}
	}

	.slider-contact-number {
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}



}
