body.contactpage {
    svg {
        max-width: 15rem;
    }

    .logo {
        max-width: 100%;
        max-height: 25rem;
    }

    .g-recaptcha {
        display: none;
    }

	h1{
		@include media-breakpoint-down(lg){
			margin-top: 2rem;
		}
	}
}

.contact__box{
	&:last-of-type{
		&::after{
			display: none;
			@include media-breakpoint-down(lg){
				display: block;
			}
		}
	}
	&::after{
		content:'';
		position: absolute;
		top: 102%;
		width: 60%;
		height: 1px;
		background-color: $blue;
		opacity: .3;
		@include media-breakpoint-down(lg){
			width: 90%;
		}
	}
	color: $black;
	padding: 2rem 1.5rem;
	h3{
		font-weight: bolder;
		margin: 1rem 0;

	}
	&__title{
		font-size: 4rem;
		font-weight: bold;
		color: $primary;
	}
	&__address,&__postAddress{
		font-size: 1.4rem;
		line-height: 2.6rem;
		letter-spacing: 0.03em;

	}
	&__telephone,&__email{
		a{
			display: flex;
			align-items: center;
			font-size: 1.4rem;
			margin: 1rem 0;
			&:last-of-type{
				margin-bottom: 0;
			}
			svg{
				margin-right: 1rem;
				width: 3.5rem;
				height: auto;
			}
			span a{
				margin-top: 0;
			}
		}
	}
	&__description{
		font-size: 1.4rem;
		line-height: 2.6rem;
		letter-spacing: 0.03em;
		font-weight: bold;
		span{
			font-weight: normal;
		}
	}

}

.contact__form{
	@include media-breakpoint-down(lg){
		margin-top: 2rem;
	}
	h3{
		position: relative;
		font-weight: bold;
		@include media-breakpoint-down(lg){
			margin-bottom: 2rem;
			font-size: 2rem;
		}
		&::after{
			content:'';
			position: absolute;
			height:3px;
			width:50px;
			background-color: $blue;
			top:120%;
			left:0;
		}
	}
}

