.accordion__list{
	&__heading__container{
		background-color: $primary;
		border-radius: $border-radius;
		padding: 1rem 2rem;
		position: relative;
		@include media-breakpoint-up(xl){
			padding: 2rem 4rem;
		}
		& span{
			text-align: left;
			display: block;
			color: $white;
			font-size: 1.8rem;
			font-weight: 400;
			letter-spacing: 0.07em;
			line-height: 2.6rem;
			cursor: pointer;
			@include media-breakpoint-up(xl){
				font-size: 2rem;
			}
		}
		&::after{
			content:url(../../../images/modules/arrow-down.svg);
			position: absolute;
			right: 10px;
			top: calc(50% - 5px);
			height: 10px;
			@include transition;
			@include media-breakpoint-up(xl){
				right: 34px;
			}
		}
	}
	&__items{
		overflow: hidden;
		@include transition;
		background-color: $light;
		border-radius: 0 0 $border-radius $border-radius;
		width: 99%;
		margin: 0 auto;
		text-align: left;
		box-shadow: 0px 5px 6px 1px rgba( 0,0,0,0.11);
		&__container{
			padding: 3rem 3rem 6rem 3rem;
		}
	}
	p{
		margin: 0;
	}
}
.accordion__list.open {
	& .accordion__list__heading__container{
		&::after{
			transform: rotate(180deg);
		}
	}
}

