body.szlifowanie-wroclaw{
	#content{

		video{
			height:380px;
			width:100%;
			position: relative;
			border: 4px solid $light;
			padding: 20px;
			@media (max-width:700px){
				height: 250px;
			}
		}
		p{
			margin-bottom: 2rem;
		}
		h3{
			text-align: center;
			color: $blue;
			font-weight: bold;
		}
		p.video__title{
			text-align: center;
			@media(max-width:1024px){
				font-size: 1.3rem;
			}
			@media (max-width:768px){
				margin-top: 2rem;
				font-size: 1.5rem;
			}
		}
		.video{
			&__section{
				justify-content: center;
			}
		}
	}

}
