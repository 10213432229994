.blog-img{
	width: 100%;
	img{
		margin: 3rem auto;
		display: block;
		@include media-breakpoint-down(sm){
			width: 100%;
		}
		// height: auto;
	}
}
.blog__heading{
	h1{
		color: $primary;
		text-align: center;
		margin-bottom: 3rem;
	}
}

.blog__back{
	margin-top: 5rem;
	margin-bottom: 5rem;
	@include media-breakpoint-down(sm){
		margin-bottom: 4rem;
	}

}

.aktualnosci, .realizowane-projekty{
	#breadcrumbs{
		.breadcrumbs__title{
			h2{
				font-size: 1.8rem;
				line-height: 2.2rem;
				@include media-breakpoint-up(md){
					font-size: 2.1rem;
					line-height: 2.6rem;
				}
				@include media-breakpoint-up(lg){
					font-size: 3rem;
					line-height: 3.3rem;
				}
				@include media-breakpoint-up(xl){
					font-size: 4rem;
					line-height: 6rem;
				}
				@media (min-width: 1920px){
					font-size: 5.9rem;
					line-height: 7.1rem;
				}
			}
		}
	}
	.blog{
		margin-bottom: 4rem;
		@include media-breakpoint-up(lg){
			margin-bottom: 5rem;
		}
	}
}
.realizowane-projekty{
	.moduletable{
		margin-top: 3rem;
		@include media-breakpoint-up(lg){
			margin-top: 5rem;
		}
		h3:not(.regional__info__title h3){
			display: none;
		}
	}
	.item-page{
		margin-top: 3rem;
	}
}
