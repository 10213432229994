#blog-loader-container {
	display: flex;
	justify-content: center;

}
.svg-loader {
	animation: rotate infinite 1s linear;
	fill: $primary;
	path {
		fill: $primary;
	}
}
@keyframes rotate {
	from {transform: rotate(0);}
	to {transform: rotate(360deg);}
}
