#company-information{
    background-image: url('../../../images/strona-glowna/dane-firmy/company-information-bg-mobile.jpg');

    @include media-breakpoint-up(lg){
		background-image: url('../../../images/strona-glowna/dane-firmy/company-information-bg.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}
	@media screen and (min-width:1921px) {
		background-size: cover;
	}

}
.company-information{
    padding: 4rem 0;
    @include media-breakpoint-up(xl){
        padding: 8rem 0 7.5rem 0;
	}
	@media screen and (min-width:568px) and (max-width: 1024px){
		text-align: center;
	}
    &__logo {
        &::after{
            content: "";
            display: block;
            height: 5px;
            width: 120px;
            background-color: $white;
            margin: 3rem 0;
            @include media-breakpoint-up(lg){
                width: 90px;
                height: 2px;
			}
			@media screen and (min-width:568px) and (max-width: 1024px){
				margin: 2rem auto;
			}
        }
    }
    &__first-item{
        flex-direction: column-reverse;
        display: flex;
        @include media-breakpoint-up(lg){
            flex-direction: row;
            display: block;
        }
    }
    &__address{
       margin: 2rem 0 3rem 0;
       font-size: 1.4rem;
       line-height: 21px;
       letter-spacing: 0.07em;
       @include media-breakpoint-up(lg){
           margin: 0;
           font-size: 1.5rem;
           line-height: 26px;
       }
    }
    &__socials{
        @include media-breakpoint-up(lg){
            margin-top: 1.6rem;
        }
        a:first-child{
            margin-right: 2rem;
        }
    }
    span{
        color: $white;
    }
    a{
        text-decoration-color: $white;
    }
    .fax{
        @include media-breakpoint-up(xl){
            a img{
				display: none;

            }
        }
        span{
            @include media-breakpoint-up(xl){
                &::before{
                    content: "FAX: ";
                }
            }
        }
    }
    .tel, .mail, .fax{
        margin-bottom: 2rem;
        @include media-breakpoint-up(lg){
            margin-bottom: 0;
            margin: 0 auto;
            display: block;
            text-align: center;
		}

			& div {
				@media screen and (min-width:1024px) and (max-width: 1279px){
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					height: 100%;
				}
				a{
					@media screen and (min-width:568px) and (max-width: 1024px){
						display: block;
					}
				}
			}


            img{
				margin-right: 2rem;
				@media screen and (min-width:1024px) and (max-width: 1279px){
					margin: 1rem auto;
					display: block;
				}

            }
            a{
                display: flex;
                align-items: center;
                line-height: 0px;
                span{
                    font-size: 2.4rem;
                    font-weight: 900;
                    line-height: 29px;
					letter-spacing: 0.031em;
					@media screen and (min-width:1024px) and (max-width: 1279px){
						font-size: 2rem;
					}
                    @include media-breakpoint-up(xl){
                        font-size: 2.2rem;
                        line-height: 2.6rem;
                    }
                }
            }
    }
    &__desc {
        margin-top: 2rem;
        font-size: 1.1rem;
        letter-spacing: 0.07em;
        line-height: 1.6rem;
        color: $white;
        opacity: .5;
        font-weight: 300;
        @include media-breakpoint-up(lg){
			margin-bottom: 0;
        }
    }

}
.mobile-reset{
    @include media-breakpoint-down(md){
        padding: 0!important;
    }
}
.company-information__list{
    text-align: center;
    display: block;
    @include media-breakpoint-up(lg){
        text-align: left;
    }
     a{
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.4rem;
        letter-spacing: 0.05em;
        line-height: 1.7rem;
        color: $black;
        @include media-breakpoint-up(xl){
            font-size: 1.5rem;
            line-height: 1.8rem;
            font-weight: 600;
		}
    }

    &__devices{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        z-index: 100;
		position: relative;
		@include transition;
        @include media-breakpoint-down(lg){
            overflow: hidden;
			width: 100%;
			height: 0;
		}

        &::after{
            content: '';
            width: 200vw;
            height: 100%;
            background-color: $white;
            position: absolute;
            top: 0;
            left: -50%;
            z-index: -1;
        }
        @include media-breakpoint-up(lg){
            padding: 0 0 6rem 0;
            background-color: #F8F8F8;
            &::before{
                content: '';
                width: 100%;
                height: 1px;
                display: block;
                background-color: #DEDEDE;
                margin-bottom: 1.5rem;
            }
            &::after{
                display: none;
            }
		}
		@media screen and (min-width:1024px) and (max-width: 1279px){
			padding: 0 0 12rem 0;
		}
    }
}

.under-dropdown-content{
    display: flex;
    flex-direction: column;
    padding: 3rem 0;
    @include media-breakpoint-up(lg){
        margin-top: 1.5rem;
        padding: 8.8rem 0 8rem 0;
	}
	@media screen and (min-width:1024px) and (max-width: 1279px){
		padding: 7.8rem 0 2rem 0;
	}
    a{
        @include media-breakpoint-up(lg){
            text-decoration: none;
        }
    }
    a:first-child{
        margin-bottom: 2.5rem;
        @include media-breakpoint-up(lg){
            margin-bottom: 1.7rem;
        }
    }
}
.list-item{
    padding: 1.4rem 0;
    &:first-of-type{
        @include media-breakpoint-down(md){
            margin-top: 1.5rem;
        }
    }
    &:last-of-type{
        @include media-breakpoint-down(md){
            margin-bottom: 1.5rem;
        }
    }
    @include media-breakpoint-up(lg){
        padding: 0;
        margin-bottom: 1.7rem;
        & a{
			text-decoration: none;
			&:hover{
				text-decoration: underline;
				opacity: 1;
			}
        }
    }
}

span.company-information__list__heading{
    font-weight: 900;
    text-decoration: none;
    padding: 3.5rem 0 0rem 0;
    display: inline-block;
    @include media-breakpoint-down(md){
        &::after{
            content: "";
            margin-left: 1rem;
            border: solid #000;
            border-width: 0 3.5px 3.5px 0;
            display: inline-block;
            width: 10px;
            height: 10px;
            transform: translate(50%, 35%) rotate(45deg);
            position: absolute;
            top: 34px;
            transition: .3s all;

        }
	}
	@media screen and (min-width:1024px) and (max-width: 1279px){
		padding: 4rem 0 2rem 0;
	}
    @include media-breakpoint-up(xl){
        padding: 5rem 0 2rem 0;
	}

}
.open span.company-information__list__heading{
	padding-bottom: 2rem;
}
