.career{

    .career-item{
        margin-bottom: 3rem;

        &:last-of-type{
            @include media-breakpoint-down(md){
                margin-bottom: 0;
            }
        }
    }
    &__info{
        margin-bottom: 3rem;
        h2{
            @include media-breakpoint-down(lg){
                margin-bottom: 2rem;
            }
        }
    }
    &__box{

        padding: 3rem 3rem 6rem 3rem;
        height: 100%;
		color: $white;
		@include media-breakpoint-down(md){
            padding: 3rem;

		}
        h3{
			color: $white;
            margin: 0;
            margin-bottom: 1em;
        }
		p{
			color: $white;

		}
        ul{
			@include media-breakpoint-down(lg){
				padding-left: 2rem;
			}
            li{
                font-size: 1.4rem;
                letter-spacing: 0.07em;
                line-height: 2.1rem;
                font-weight: 400;
                @include media-breakpoint-up(lg){
                    font-size: 1.5rem;
                    line-height: 2.6rem;
                }
            }
        }
    }
    .box-cv{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3rem 0;
        .btn{
            margin: 4rem 0;
            display: inline-block;
        }
    }

}
.career-form{
	width: 100%;
	padding: 1rem;
	border: 2px solid $light;
	margin: 0 auto 5rem;
	& .btn{
		margin: 0 auto;
		display: block;
	}
	@include media-breakpoint-up(lg){
		width: 40%;
		padding: 3rem;
		margin: 0 auto;
	}
	.fileinfo{
		margin-top: 1rem;
	}

}


