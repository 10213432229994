body.ciecie-tlenowe-wroclaw{
	#content{
		.cutting{
			&__video{
				position: absolute;
				top:11rem;
				right: 1.5rem;
				display: none;
				@include media-breakpoint-up(lg){
					display: block;
				}
				&--mobile{
					display: none;
					margin: 1rem 0 2rem 0;
					@include media-breakpoint-down(md){
						display: block;
						max-width:100%;
						margin: 1rem 0;
					}
				}

			}
			&__title h3{
				margin-top: 0;
				color: $primary;
				@include media-breakpoint-down(md){
					margin-bottom: 2rem;
				}
			}
			&__description{
				ul{
					list-style-type: none;
					li{
						position: relative;
						padding: 1rem 0;
						font-size: 1.5rem;
						line-height: 2.3rem;
						padding-left: .5rem;
					&::before{
						content:url(../../../images/obrobka-skrawaniem/ciecie-tlenowe/welding.svg);
						position: absolute;
						left:-32px;
					}
					}
				}
			}
		}
		.mobile__video{
			display: flex;
			justify-content: center;
		}
	}
}
