.micronization{
	&-info{
		margin-top: 3rem;
		@include media-breakpoint-down(sm){
			margin-top: 0;
		}
		p{
			margin-bottom: 2rem;
			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}
	.img-wrapper {
		@include media-breakpoint-down(md) {
			margin-top: 3rem;
		}
	}
}
.micronizers{
	@include media-breakpoint-down(sm){
		margin-top: 3rem;
	}
	h2{
		color: $primary;
	}
	.img-wrapper{
		@include media-breakpoint-down(md){
			margin-bottom: 3rem;
		}
	}
}
.mikronizatory{

	// #content section{
	// 	padding: 4rem 0;
	// 	@include media-breakpoint-up(lg){
	// 		padding: 8rem 0;
	// 	}
	// }
	.img-wrapper {
		height: 30rem;
		@include media-breakpoint-up(lg){
			height: initial;
		}
		@include media-breakpoint-up(xl){

		}
		img {
			width: 100%;
			border: .1rem solid rgba($blue, .2);
			height: 100%;
			object-fit: cover;
			border-radius: .8rem;
		}
	}
}
