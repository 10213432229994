.rodo{
	li{
		margin-bottom: 2rem;
	}
	ol{
		padding-top: 1rem;
		@include media-breakpoint-down(md){
			padding-left: 1rem;
		}
	}
}
.iso{

}
.iso-btn{

	margin: 3rem auto;
	display: flex;

	.btn{
		display: inline-block;
		margin: 0 auto;
	}
}

section.iso{
	.box{
		margin-top: 3rem;
	}
}
.ciasteczka{
	#breadcrumbs{
.breadcrumbs__title{
	@media (max-width:360px){
		top: 8rem;
	}
}

		h2{
			@media (max-width:360px){
				font-size: 2.9rem;
				line-height: 3.3rem;

			}
		}

	}
}
