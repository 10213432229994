#partners{
	padding-bottom: 5rem;
	header.section__heading{
		z-index: 1000;
		position: relative;
		margin-top: -1rem;
		@include media-breakpoint-up(lg){
			margin-top: 2rem;
		}
	}
	.partner{
		align-self: center;
		@include media-breakpoint-down(md){
			align-self: center;
			width: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-bottom: 3rem;
			& img{
				margin: 0 auto;
				display: block;
			}
		}
		@include media-breakpoint-up(lg){
			margin-right: 2.5rem;
		}
		@media (max-width: 320px){
			width: 100%;
			margin-bottom: 2.5rem;
		}
		&:nth-of-type(2) img{
			@include media-breakpoint-up(lg){
				margin: 0 auto 0 0.5rem;
				display: block;
			}
		}
		&:nth-of-type(5) {
			@include media-breakpoint-down(md){
				width: 100%;
				margin-bottom: 0;
			}
		}
		img{
			width: 150px;
			min-height: 30px;
		}
	}

}
