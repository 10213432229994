$bar-height: 6rem;

.mobile-bar {
    background: $primary;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    text-align: center;
    color: white;
	border-top: 0.1rem solid white;
	width: 100%;
	height: $bar-height;

    &-item {
        border-right: 0.1rem solid white;
        align-items: center;
        justify-content: center;
        &:last-of-type {
            border: none;
        }
        a {
            padding: 1.5rem;
            display: block;
            svg {
				height: 3rem;
                path {
                    fill: white;
                }
            }
            &:hover {
                background: rgba(0, 0, 0, 0.2);
            }
        }
	}
	@media (min-width:1200px) {
		display: none;
	}
}


