.bloki-lamelowe, .podstawki-magnetyczne, .pryzmy-magnetyczne, .ustawiaki-magnetyczne {


	#content{
		.landing__photo{
			img{
				margin-top: 5rem;
			}
		}
		h3{
			color: $blue;
			text-align: center;
			@include media-breakpoint-down(md){
				margin: 2rem 0;
			}
		}
		img{
			max-width: 100%;
		}
		ul{
			list-style-type: none;
			position: relative;
			margin-bottom: 4rem;
			li{
				margin-bottom: 1.5rem;
				font-size: 1.5rem;
				line-height: 2rem;
				&::before{
					content:url('../../../images/urzadzenia-magnetyczne/akcesoria-magnetyczne/info.svg');
					position: absolute;
					left:5px;
				}
			}
		}
		.table{
			&-wrapper{
				@include media-breakpoint-down(sm){
					max-width: 100%;
				}
			}
			&__content{
				display: flex;
				justify-content: space-evenly;
				padding-bottom: 4rem;
				position: relative;
				@include media-breakpoint-down(sm){
					flex-direction: column;
					align-items: center;
					overflow-x: scroll;
					padding: 0 1.5rem 0rem 1.5rem;
				}

			}
			&__image{
				width: 30%;
				@include media-breakpoint-down(sm){
					width:70%;
					&:nth-of-type(odd){
						margin-bottom: 2rem;
					}
					&:nth-of-type(even){
						margin-top: 2rem;
					}
				}


			}
		}

		.table-element{
			display: flex;
			flex-direction: column;
			padding-top: 4rem;

			@include media-breakpoint-up(lg){
				flex-direction: row;
				padding-top: 8rem;
			}
			.text-before{
				width: 100%;
				position: relative;
				z-index: 100;
				@include media-breakpoint-up(lg){
					width: 40%;
				}
				h3{
					// color: $white;
					color: $primary;
					margin: 0;
					padding: 3rem 0rem 0rem 3rem;
					font-size: 2.8rem;
					text-align: left;
				}
			}
			.table__image{
				width: 100%;
				z-index: 1;
				@include media-breakpoint-up(lg){
					width: 60%;
				}
			}
		}
		.magnetic-setter,.magnetic-prism{
			&__image{
				cursor: pointer;
			}
			&__content{
				@include media-breakpoint-down(sm){
					border-bottom: 1px solid $blue;
				}
			}
			&__box{
				@include media-breakpoint-down(sm){
					margin-bottom: 2rem;
				}
			}
			&__description{
				margin-bottom: 2rem;
				h3{
					text-align: left;
				}
			}
		}
		.table-cont{
			@include media-breakpoint-up(lg){
				padding: 4rem 0;
				&:last-of-type{
					padding-bottom:0;
				}
			}
		}
		.table-wrapper--prisma{
			table{
				th:first-of-type{
					padding: 1.5rem 8rem;
				}
			}
		}
	}



}
.podstawki-magnetyczne{
	.table__content{
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
	.full-background{
		&::before{
			z-index: 0;
		}
	}
	.img-wrapper{


		@media (max-width: 822px) and (orientation: landscape){
			margin-bottom: 0;
		}
		@include media-breakpoint-up(lg){
			margin-bottom: 4rem;
		}
	}
	.last-element{
		.table__content{
			@include media-breakpoint-up(md){

				margin-bottom: 0;
			}
		}
	}
}
