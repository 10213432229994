.mservices-hero{
	.mservices-info {
		@include media-breakpoint-up(lg){
			margin-top: 3rem;
		}

		p {
			margin-bottom: 2rem;
		}
	}
	.img-wrapper {
		height: 30rem;
		position: relative;

		@include media-breakpoint-up(lg) {
			height: initial;
		}

		@include media-breakpoint-up(xl) {}

		img {
			width: 100%;
			border: .1rem solid rgba($blue, .2);
			height: 100%;
			object-fit: cover;
			border-radius: .8rem;
		}
	}
}
.mservices{
	position: relative;
	@include media-breakpoint-up(xl){

		&::before {
			content: "";
			height: 30%;
			width: 100vw;
			position: absolute;
			margin-left: -50vw;
			left: 50%;
			top: 63%;
			z-index: -1;
			transform: translateY(-50%);
			background: $primary;
		}
	}


	&-offer{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
		@include media-breakpoint-up(lg){
			flex-wrap: nowrap;
		}
		&__card{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			border-radius: .8rem;
			background: $white;
			padding: 2rem;
			box-shadow: 0 0 1.2rem rgba($black, .2);
			transition: .3s ease-in;
			border: .1rem solid $white;
			width: 100%;
			min-height: 20rem;
			margin-bottom: 2rem;
			@include media-breakpoint-up(md){
				width: 49%;
			}
			@include media-breakpoint-up(lg) {
				width: 24%;
				min-height: 33rem;
				margin-bottom: 0;
			}
			@include media-breakpoint-up(xl){
				min-height: 32rem;
			}
			h3{
				margin-top: 0;
				text-transform: capitalize;
				text-align: center;
				transition: .3s ease-in;
				font-weight: 700;
				// margin-bottom: 3rem;
			}
			svg {
				overflow: visible;
				height: 8rem;
				width: 8rem;
				@include media-breakpoint-up(xl) {
					height: 12rem;
					width: 12rem;
				}
				path{
					transform-box: fill-box;
					transform-origin: center;
					transition: .5s ease-in;

				}
				#color,
				#circle1,
				#circle2 {
					transform-box: fill-box;
					transform-origin: center;
					transition: .5s ease-in;
				}
			}
			@include media-breakpoint-up(lg) {
				&:hover {
					border: .1rem solid $blue;
					h3{
						color: $primary;
					}
					#dust{
						#star1,#star2,#star3{
							fill: $blue;
							transform: scale(1.3) rotate(180deg);
						}
					}
					#measure{
						#color{
							height: 40rem;
							transform: translateY(-39rem);
							fill: $blue;
						}
						#arrow{
							transform: scaleY(.8)
						}
					}
					#consultation{
						#person1{
							fill: $primary;
						}
						#person2{
							fill: $blue;
						}
						#bubble{
							transform: translateY(-2rem)
						}
					}
					#project{
						#circle1{
							transform: rotate(180deg);
						}
						#circle2{
							transform: rotate(360deg);
							path{
								fill: $blue;
							}
						}

					}
				}
			}
		}
	}

}

