#gaussmeters {
	.gaussmeter {
		&__element {
			margin-top: 4rem;
			display: flex;
			flex-direction: column;
			@include media-breakpoint-down(md){
				&:last-of-type{
					margin-bottom: 0;
				}
			}
			&__img {
				img {
					margin: 0 auto;
					display: block;
					width: 100%;

					height: auto;

					@media (max-width: 822px) and (orientation: landscape) {
						width: 60%;
					}

					@include media-breakpoint-up(lg) {
						margin: initial;
						width: 60%;
					}
				}
			}

			&__content {
				display: flex;
				flex-direction: column;
				align-items: center;

				@include media-breakpoint-up(lg) {
					align-items: flex-start;
				}

				h3 {
					color: $primary;
					margin-top: 2rem;

					@include media-breakpoint-up(lg) {}
				}

				ul {
					list-style: none;
					padding-left: 4rem;
					// margin-bottom: 0;
					li {
						margin-bottom: 1.5rem;
						font-size: 1.7rem;
						position: relative;

						&::before {
							content: url('../../../images/urzadzenia-magnetyczne/akcesoria-magnetyczne/speedometer.svg');
							position: absolute;
							left: -3.5rem;

						}
					}
				}
			}
		}
	}
}
