#regional{
	@include media-breakpoint-up(xl){
		margin: 4rem 0;
	}
}
.regional__info{
    &__title{
        h3{
            margin: 0 0 1rem 0;
            @include media-breakpoint-up(lg){
                margin-bottom: 0.5rem;
            }
        }
        p{
            margin: 0;
            font-weight: 900;
            margin-bottom: 1.5rem;
            @include media-breakpoint-up(lg){
            letter-spacing: 0.05em;
            line-height: 18px;
			margin-bottom: 0;
			font-weight: 900 !important;
            }
        }
    }
    &__items{
        margin-top: 1.5rem;
        @include media-breakpoint-up(lg){
            margin: 0;
        }
    }
    &__item{
        margin: 1.4rem 0;
        @include media-breakpoint-up(lg){
            margin: 0;
        }
        h4{
            font-size: 1.4rem;
            font-weight: 900;
            text-transform: uppercase;
			margin-bottom: 0;
			@media screen and (min-width:1024px) and (max-width: 1279px){
				margin-top: 2rem;
			}
            @include media-breakpoint-up(lg){
				font-size: 1.5rem;
			}
			&::after{
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				background-color: #707070;
				opacity: .3;
				margin: 1rem 0;
				@include media-breakpoint-up(lg){
					margin: 1rem 0 2rem 0;
				}

			}
        }
        & p{
            font-size: 1.2rem;
			font-weight: 400;
			@media screen and (min-width:1024px) and (max-width: 1279px){
				margin: 0;
			}
            @include media-breakpoint-up(lg){
				line-height: 26px;
            }
        }
    }
}
.regional__organizations{
		display: flex;
		justify-content: space-evenly;
		margin-bottom: 2rem;
		flex-wrap: wrap;
		width: 100%;
		@media screen and (max-width: 823px) and (orientation: landscape){
		flex-wrap: nowrap;
		// padding: 0 2rem;
		// width: ;
		}
        @include media-breakpoint-up(lg){
            margin-top: 3rem;
			margin-bottom: 0;
			justify-content: space-around;
			flex-direction: column;
			height: 100%;
			align-items: center;
        }
}
.fund-mobile{
	@include media-breakpoint-up(xl){
		display: none;
	}
}
.fund-desktop{
	@include media-breakpoint-down(lg){
		display: none;
	}
}
.reverse{
    flex-direction: column-reverse;
    @include media-breakpoint-up(lg){
        flex-direction: row;
    }
}

