.our-story{

	.headquarter-img{
		img{
			border-radius: 8px;
			height: 100%;
			@include media-breakpoint-down(lg){
				width: 100%;
				margin-top: 3rem;
			}
		}
	}
	.box{
		margin-top: 3rem;
	}
}
