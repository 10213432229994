@import "../utils/variables/other";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400&display=swap');

a, p, span, label,
h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

.collapse:not(.show) {
    display: none;
}

a:not(.btn) {
    font-weight: bold;
}

p > small {
	font-size: 1.2rem;
}
header.section__heading{
	margin-top: 4rem;
	@include media-breakpoint-up(xl){
		margin-top: 8rem;
	}
	& h1, h2{
		font-size: $h1-heading;
		font-weight: 900;
		letter-spacing: 0.09em;
		line-height: 3.2rem;
		color: $primary;
		@include media-breakpoint-up(lg){
			font-size: 4.8rem;
			letter-spacing: 0.05em;
			line-height: 5.7rem;
			margin: 0;
		}
	}
	& p, span{
		font-size: $p-heading;
		letter-spacing: 0.07em;
		font-weight: 500;
		line-height: 2.1rem;
		display: block;
		color: $black;
		@include media-breakpoint-up(lg){
			font-size: 2.8rem;
			letter-spacing: 0.05em;
			line-height: 3rem;
			// margin: 2rem 0 4rem 0;
		}
	}
	&::after{
		content: "";
		display: block;
		height: 5px;
		width: 120px;
		background-color: $blue;
		margin: 3rem 0;
		@include media-breakpoint-up(lg){
			width: 9rem;
			margin: 4rem 0;
		}
	}
}
h2, .h2{
	font-size:$h2-fz;
	font-weight: 700;
	letter-spacing: 0.07em;
	line-height: 2.1rem;
	color: $black;
	@include media-breakpoint-up(lg){
		font-size: 2.8rem;
		letter-spacing: 0.05em;
		line-height: 3rem;
		margin: 4rem 0;
	}
}

h3, .h3, h4{
	font-size:$h3-fz;
	font-weight: 500;
	letter-spacing: 0.07em;
	line-height: 2.1rem;
	color: $black;
	@include media-breakpoint-up(lg){
		font-size: 2rem;
		letter-spacing: 0.07em;
		line-height: 2.6rem;
		margin: 4rem 0 2rem 0;
	}
}
p{
	font-size: $p-fz;
	letter-spacing: 0.07em;
	line-height: 2.1rem;
	font-weight: 400;
	color: $black;
	&{
		strong{
			font-weight: black;
		}
	}
	@include media-breakpoint-up(lg){

		font-size: 1.5rem;
		line-height: 2.6rem;
	}
}
a:not(.btn) {
	font-weight: 900;
	font-size: 1.4rem;
	line-height: 2.1rem;
	letter-spacing: 0.07em;
	color: $primary;
	@include media-breakpoint-up(lg){
		font-size: 1.5rem;
		line-height: 2.6rem;
		@include transition();
		&:hover{
			opacity: .6;
		}
	}
}


.box{
	background-image: url("../../../images/typografia/text-box/bussiness-people-working-in-team-in-an-office.jpg");
	background-color: rgba(0, 43, 72, 0.89);
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: auto;
	position: relative;
	z-index: 100;
	&::after{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 43, 72, 0.89);
		top: 0;
		left: 0;
		z-index: -1;
	}
&--text{
	p{
		color: $white;
		margin: 0;
		padding: 3rem 3rem 6rem 3rem;
	}
}

&__btn{
	padding: 6rem 0 8rem 0;
	display: flex;
	justify-content: center;
}

&__link{
	padding: 3rem 3rem 4rem 3rem;
	@include media-breakpoint-up(lg){
		padding: 3rem 9rem 6rem 3rem;
	}
}

&--link{
	a{
		color: $white;
	}
}
}

.home{
	position: relative;
}
.right-side-box{
	background-color: $primary;
    display: block;
    width: 50vw;
    position: absolute;
	right: -9vw;
    border-radius: 2*$border-radius;
	transform: skewX(-13deg);
	@include media-breakpoint-down(lg){
		width: 100%;
		position: relative;
		right: -22vw;
		border-radius: $border-radius;
	}
	&__inner{
		padding: 2rem 0;
		@include media-breakpoint-down(lg){
			padding: 1rem 0;
		}
		&-bg{
			transform: skewX(13deg) translateX(10rem);
			padding-left: 10rem;
			transition: .5s;
			@include media-breakpoint-down(lg){
				transform: skewX(13deg) translateX(-2rem);
			}
			&:hover{
				transform: skewX(13deg) translateX(0rem);
				@include media-breakpoint-down(lg){
					transform: skewX(13deg);
					transform: skewX(13deg) translateX(-2rem);
				}
			}
			& .bg-container{
				transform: skewX(-13deg);
				overflow: hidden;
				border-radius: 2*$border-radius;
				position: relative;
				background-color: $white;
				@include media-breakpoint-down(lg){
					border-radius: $border-radius;
					transform: skewX(-13deg) translateX(-2rem);
				}
				picture {
					transform: skewX(13deg);
					display: block;
				}
				img{
					max-height: 58rem;
					margin-left: -9rem;
					opacity: 0.55;
					transform: translateY(3px);
					@include media-breakpoint-down(lg){
						max-height: 23rem;
						margin-left: -3rem;
						width: 100%;
					}
					@media (max-width: 480px) and (orientation: landscape){
						margin-left: -4rem;
					}
				}
			}
		}
		&__logo{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-75%);
			@include media-breakpoint-down(lg){
				left: 47%;
				top: 45%;
				transform: translateX(-25%);
			}
			img{
				opacity: 1 !important;
				@include media-breakpoint-down(md){
					max-width: 80%;
				}
				@include media-breakpoint-down(sm){
					max-width: 100%;
				}
			}
		}
	}
}
.typo{
	margin-top: 8rem;
	@include media-breakpoint-up(xl){
		margin-top: 15rem;
	}
}
.forms-samples{
	margin-top: 8rem;
	@include media-breakpoint-up(xl){
		margin-top: 10rem;
	}
}
#chat-application{
	z-index: 10001 !important;


}
 .fab {
	background-image: none !important;
}

.custom-checkbox, .rodo-container{
	position: relative;
	input {
		width: 17px;
		height: 17px;
		align-self: center;
		position: absolute;
		top:4%;
		left: 0%;
		appearance: none;
		outline: none;
		border: 1px solid $primary;
		padding: 0;
		z-index: 1;
		&::after {
			content: "";
			position: absolute;
			visibility: visible;
			top:0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			border-radius: .4rem;
		}
	}
	input[type=checkbox] {
		appearance: none;
		outline: none;
		cursor: pointer;
		z-index: 1;
	}
	label{
		margin-left: 3rem;
	}


	input[type=checkbox]:checked {
		background: $primary url("../../../images/typografia/check.svg") no-repeat center;
		background-size: 1.1rem;
	}
}
