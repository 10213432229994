.demagnetizer{
	position: relative;
	padding: 4rem 0;
	&:first-of-type{
		padding-top: 0;
		margin-top: 4rem;
	}
	@include media-breakpoint-up(lg){
		padding: 8rem 0;
		&:first-of-type{
			padding-top: 0;
			margin-top: 8rem;
		}
	}
	.section__heading{
		margin-top: 0;

	}
	&:nth-of-type(even){
		&::before{
			content: "";
			height: 100%;
			z-index: -1;
			width: 100vw;
			position: absolute;
			margin-left: -50vw;
			left: 50%;
			top: 0;
			background: $light;
		}
	}
	&-description {
		// margin-bottom: 3rem;
		position: relative;
		padding: 2rem 0;
		text-align: center;
		@include media-breakpoint-up(xl){
			width: 60%;
			margin: 0 auto 3rem auto;

		}
		p{
			color: $white;
		}
		&::before{
			content: "";
			height: 100%;
			z-index: -1;
			width: 100vw;
			position: absolute;
			margin-left: -50vw;
			left: 50%;
			top: 0;
			background: $primary;
		}
	}
	&-info{
		display: flex;
		margin: 3rem 0;
		justify-content: center;
		flex-direction: column;
		@include media-breakpoint-up(lg){
			flex-direction: row;
		}

		h3{
			font-size: 2rem;
			line-height: 2.4rem;
			margin: 2rem 0 1rem 0;
			color: $primary;
			letter-spacing: .05em;
			font-weight: 700;
			@include media-breakpoint-up(lg){
				margin: 0;
				margin-bottom: 2rem;
				font-size: 2.8rem;
				line-height: 3rem;

			}
		}
		.info-wrapper{
			display: flex;
			flex-direction: column;
			@include media-breakpoint-up(lg){
				margin-left: 3rem;
			}
		}
		p{
			font-size: 1.6rem;
			margin-bottom: 1rem;
			@include media-breakpoint-up(lg){
				font-size: 2rem;

			}
		}
		img{
			border-radius: .8rem;
			width: 100%;
			height: 20rem;
			object-fit: contain;
			@include media-breakpoint-up(lg){
				border: .1rem solid rgba(0, 108, 181, .2);
				height: 30rem;
				width: initial;

			}
		}
	}
}
.table-wrapper{
	overflow-x: auto;
	table{
		width: 100%;
		text-align: center;
		border-collapse: collapse;

		th,td{
			border-bottom: 1px solid $grey;
			padding: 1.5rem 3rem;
		}
		th:first-of-type{
			text-align: left;
		}
	}

}
