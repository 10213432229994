body.separatory-magnetyczne{
	#content{
		.separators__img{
			position: absolute;
			top:11rem;
			right: 1.5rem;
			img {
				display: none;
				@include media-breakpoint-up(xl){
					display: block;
					border-radius: $border-radius;

				}
			}
		}
		img{
			max-width: 100%;
			cursor: pointer;
			border-radius: 8px;
			border: 1px solid rgba(0,108,181,0.2);
			@include media-breakpoint-down(md){
				margin: 1rem 0;
			}
		}
		h2{
			margin: 3rem 0;
		}
		p{
			margin-bottom: 2rem;
		}
		.magnetic{
			&__separators{
				&:last-of-type{
					margin-top: 3rem;
					@include media-breakpoint-down(md){
						margin-bottom: 3rem;
						
					}
					@include media-breakpoint-down(sm){
						margin-bottom: 0;
					}
				}
				position: relative;
				@include media-breakpoint-down(sm){
					flex-direction: column-reverse;
				}

				&__list{
					list-style-type: none;
					margin-right: 4rem;
					li{
						position: relative;
						padding: 1rem 0;
						font-size: 1.5rem;
						&::before{
							content:url(../../../images/urzadzenia-magnetyczne/separatory-magnetyczne/magnet.svg);
							position: absolute;
							left:-32px;
						}
						// border-bottom: 1px solid #dedede;
						@include media-breakpoint-down(lg){
							padding: .5rem 0;
						}
					}
				}
				h3{
					margin: 0;
					margin-bottom: 2rem;
					font-size: 1.7rem;
					font-weight: 700;
					line-height: 2.1rem;
					letter-spacing: .05em;
					color: #00558e;
					@include media-breakpoint-up(xl){
						font-size: 2.8rem;
    					line-height: 3rem;
					}
					@include media-breakpoint-down(md){
						margin: 1rem 0;
					}
				}
			}
		}

		.magnetic-lists{
			display: flex;
			@include media-breakpoint-down(sm){
				flex-direction: column;
			}
			ul{
				@include media-breakpoint-down(md){
					margin: 0;
				}
			}
		}
	}
}
