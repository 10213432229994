html,
body {
	overflow-x: hidden;
	font-family: "Lato", sans-serif;
}

html {
	font-size: 62.5%;
}

body {
	margin: 0;
	font-size: 1.6em;
}

body>section {
	padding: $sp-section 0;
	&#company-information,
	&#slider,
	&#cta-full,

	&#breadcrumbs {
		padding: 0;
	}

}



body>section#cta-full {
	background: $primary;
}

body>main {
	padding: $sp-section 0;
}

.prevent-scroll {
	overflow-y: hidden;
	height: 100%;
}

.d-none {
	display: none !important;
}

// 100vw background color
.full-background {
	position: relative;

	&::before {
		height: 100%;
		content: "";
		width: 100vw;
		position: absolute;
		margin-left: -50vw;
		left: 50%;
		top: 0;
		background: $light;
	}
}

@include media-breakpoint-down(lg) {
	body {
		padding-bottom: 0 !important;
	}
}

.menu-background {
	pointer-events: none;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

.table-wrapper {
	overflow-x: auto;

	table {
		width: 100%;
		text-align: center;
		border-collapse: collapse;

		thead {
			th {
				padding-top: 0;
			}
		}

		th,
		td {
			border-bottom: 1px solid $grey;
			padding: 1.5rem 3rem;
		}

		th:first-of-type {
			text-align: left;
		}
	}
}

.europe-flag-link {
	display: flex;
	justify-content: center;
	margin-top: 2rem;

	@include media-breakpoint-up(md) {
		position: absolute;
		top: -18.5rem;
		left: 2rem;

		@include media-breakpoint-up(xl) {
			top: -25.5rem;
			z-index: 99999;
		}

		@media (min-width:1280px) {
			left: 0.5rem;
			top: -15rem;
		}
		@include media-breakpoint-up(xxl){
			left: 1rem;
		}
		@media (min-width:1601px) {
			left: 2.5rem;
		}
	}
}

.europe-flag-home {
	width: 15rem;

	@media (min-width:1280px) {
		width: 10rem;
	}
}

.home {
	.carousel::before {
		display: none;
		content: '';
		position: absolute;
		z-index: 2;
		top: 83.4%;
		left: 50%;
		transform: translateX(-50%);
		background-image: url(../../../../images/o-phmet/unia-europejska-fundusz.png);
		background-size: contain;
		background-repeat: no-repeat;
		width: 11.5rem;
		height: 100%;

		@include media-breakpoint-up(sm) {
			top: 83.4%;
			left: 17%;
		}

		@include media-breakpoint-up(xl) {
			top: 78.4%;
			left: 7%;
		}

		@media (min-width:1280px) {
			top: 81.5%;
			left: 5%;
			width: 9rem;

		}

		@media (min-width:1600px) {
			top: 85.5%;
			left: 4.5%;
			width: 9.5rem;
		}
	}
}
