.gallery {
	&__pic {

		@media screen and (max-width: 767px) {
			width: 50%;
			object-fit: cover;
			margin-bottom: 1rem;

			& picture,
			img {
				width: 100%;
				height: 100%;
			}

			&:nth-of-type(even) {
				padding-right: 0.5rem;
			}

			&:nth-of-type(odd) {
				padding-left: 0.5rem;
			}
		}
	}

	&__item {
		height: 9.4rem;
		width: 100%;
		border-radius: $border-radius;
		overflow: hidden;
		position: relative;

		@media screen and(max-width: 320px) {
			height: 8.4rem;
		}

		@media screen and(min-width: 480px) and (max-width: 767px) and (orientation: landscape) {
			height: 11.4rem;
		}

		@include media-breakpoint-up(md) {
			height: 17rem;
			margin: 1.5rem 0;
		}

		@include media-breakpoint-up(lg) {
			height: 17rem;
		}

		@media screen and (min-width:1025px) and (max-width: 1599px) {
			height: 21rem;
		}

		@media screen and (min-width:1600px) {
			height: 25.6rem;
		}

		img {
			max-width: 100%;
			height: auto;
			margin: 0 auto;
			display: block;
			cursor: pointer;
		}
		&::before{
			content: url(../../../images/strona-glowna/fullsize-img.svg);
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			pointer-events: none;
			z-index: 2;
			opacity: 0;
			@include transition;

			@include media-breakpoint-down(lg) {
				display: none;
			}
		}

		&::after {
			content: '';
			display: flex;
			align-items: center;
			justify-content: center;
			pointer-events: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 51, 86, 0.7);
			z-index: 1;
			transform: translateY(100%);
			@include transition;

			@include media-breakpoint-down(lg) {
				display: none;
			}
		}

		&:hover {
			&::after {
				transform: translateY(0);
			}

			&::before {
				opacity: 1;
			}
		}
	}

	& .btn-gallery {
		display: flex;
		justify-content: center;

		& .btn {
			@include media-breakpoint-down(lg) {

				margin-top: 2.5rem;
			}

			@include media-breakpoint-up(xl) {

				margin-top: 2rem;
			}
		}
	}

}

#hgallery {
	display: block;
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.9);
	visibility: hidden;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 0;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	z-index: 9999999999;
}

.hg-disable-scrolling {
	overflow: hidden !important;
}

#hg-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: -1;

	&::after {
		content: '';
		position: absolute;
		display: block;
		top: 20px;
		right: 20px;
		width: 30px;
		height: 30px;
		background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xOSA2LjQxTDE3LjU5IDUgMTIgMTAuNTkgNi40MSA1IDUgNi40MSAxMC41OSAxMiA1IDE3LjU5IDYuNDEgMTkgMTIgMTMuNDEgMTcuNTkgMTkgMTkgMTcuNTkgMTMuNDEgMTJ6Ii8+CiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+Cjwvc3ZnPg==);
		background-position: center;
		background-size: contain;
		cursor: pointer;
		opacity: 0.8;
		z-index: 5;

		&:hover {
			background-color: white;
		}
	}
}

#hgallery.open {
	visibility: visible !important;
	opacity: 1;
}

#hg-pic-cont {
	max-width: calc(70% - 40px);
	max-height: 90vh;
	cursor: default;
	z-index: 12;
	position: relative;
	background-color: white;
	-webkit-transition: -webkit-transform 0.3s;
	transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
	-webkit-transform: scale(1);
	transform: scale(1);
	// margin-top: 5rem;

	&.hg-transition {
		-webkit-transform: scale(0.1);
		transform: scale(0.1);
	}
}

#hg-subtext {
	color: #ddd;
	font-size: 14px;
	position: absolute;
	display: block;
	left: 5px;
	top: calc(100% + 6px);
}

#hg-howmany {
	color: $white;
	font-size: 14px;
	position: absolute;
	display: block;
	right: 5px;
	bottom: -20px;
}

#hg-pic {
	width: auto;
	height: auto;
	min-height: 100px;
	min-width: 100px;
	max-width: 100%;
	max-height: 68vh;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: block;
	cursor: default;
	-o-object-fit: contain;
	object-fit: contain;
	margin: 0;

	/*    border-radius: 5px;*/

	&:hover {
		-webkit-transform: none;
		transform: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

#hgallery {
	button {
		position: absolute;
		display: block;
		margin: auto 0;
		width: 60px;
		height: 60px;
		z-index: 11;
		cursor: pointer;
		background-color: transparent;
		background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNOC41OSAxNi4zNGw0LjU4LTQuNTktNC41OC00LjU5TDEwIDUuNzVsNiA2LTYgNnoiLz4NCiAgICA8cGF0aCBkPSJNMC0uMjVoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz4NCjwvc3ZnPg==');
		background-size: contain;
		border: 0;
		outline: 0;
		opacity: 0;
		-webkit-transition: opacity 0.3s, visibility 0.3s;
		transition: opacity 0.3s, visibility 0.3s;
		visibility: hidden;
	}

	&.open button {
		visibility: visible;
		opacity: 0.7;
	}

	button {
		&:hover {
			opacity: 1;
		}

		&#hg-prev {
			left: 10px;
			-webkit-transform: rotate(180deg);
			transform: rotate(180deg);

			&:active {
				left: 7px;
			}
		}

		&#hg-next {
			right: 10px;

			&:active {
				right: 7px;
			}
		}
	}

	#hg-prev-onpic {
		position: absolute;
		top: 0;
		left: 0;
		width: 34%;
		height: 100%;
		cursor: pointer;
	}

	#hg-next-onpic {
		position: absolute;
		top: 0;
		left: 0;
		width: 34%;
		height: 100%;
		cursor: pointer;
		right: 0;
		left: auto;
		width: 66%;
	}
}

.hg-unvisible {
	opacity: 0 !important;
	visibility: hidden;
}

@media (max-width: 1100px) {
	#hg-pic-cont {
		max-width: calc(100% - 40px);
	}
}

dl.fields-container {
	display: none;
}

.galeria {

		div.gallery__item {
			@include media-breakpoint-down(md) {
				min-height: 17rem;
				margin-bottom: 2rem;

			}
		}
		#dj-galleryGrid1p{
			@include media-breakpoint-down(md){
				margin-bottom: -2rem;
			}
			@media (max-width: 823px) and (orientation: landscape){
				margin-bottom: 2rem;
			}
		}
}
