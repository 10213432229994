.uslugi-dodatkowe{
	.additional-services{
		@include media-breakpoint-down(md){
			margin-bottom: 4rem;
		}

	position: relative;
	.img-wrapper{
		@media (max-width: 823px) and (orientation: landscape){
			height: 20rem;

		}
		img{
		    width: 100%;
			height: 100%;
			object-fit: cover;
			margin-top: 2rem;

			@include media-breakpoint-up(lg){

				height: auto;
			}

		}
	}
	.additional-box{
		margin-bottom: 3rem;
		@include media-breakpoint-down(sm){
			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}
	.additional-separator{
		padding: 3rem 0;
		@include media-breakpoint-up(lg){
			padding: 5rem 0;
		}
		h3{
		margin: 0;
		}
	}
	.box{
		padding: 3rem 3rem 6rem;
		height: 100%;

		h4{
			margin: 0;
			color: $white;
		}

		ul{
			color: $white;
			@include media-breakpoint-down(lg){
				padding-left: 2rem;
			}
            li{
				font-size: 1.4rem;
				letter-spacing: .07em;
				line-height: 2.1rem;
                @include media-breakpoint-up(lg){
                    line-height: 2.6rem;
					font-size: 1.5rem;
                }
            }
        }
	}
}
}
