body.uchwyty-stoly-magnetyczne{
    h1{
        margin-bottom: 3rem;
        color: $primary;
        @include media-breakpoint-down(xl){
            margin-top: 3rem;
        }
    }
    p.magneticGrippers__more{
        margin-top: 3rem;
    }
    p.magneticGrippers__description{
        span{

        }
    }
    .magneticGrippers__image{
        @include media-breakpoint-down(lg){
            margin-top: 3rem;
        }
        img{
            max-width: 100%;
            height:auto;
        }
    }
	#breadcrumbs{
		h2{
			@media (max-width:320px){
				font-size: 2.9rem;
			}
		}

	}
}
