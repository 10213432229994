.blog-title {
	border-radius: $border-radius;
	background-color: $primary;
	width: 102% ;
	position: relative;
	right: 1%;
	padding: 1.2rem 1.5rem;
	@include media-breakpoint-up(xl){
		padding: 2rem 4rem;
	}
	& h3{
		color: $white;
		font-size: 1.4rem;
		font-weight: 400;
		@include media-breakpoint-up(lg){
		font-size: 2rem;
		margin: 0;
		}
	}

}
.blog{
	& .col-md-6:nth-of-type(3) {

		@include media-breakpoint-up(md){

			margin: 3rem auto 0;
		}
		@include media-breakpoint-up(lg){

			margin: 0rem auto 0;
		}

	}
}

.blog-item-link{
	&:hover{
		.blog-title{
			opacity: 1 !important;
		}
	}
}


.blog-img-wrapper {
    display: block;
    max-height: 9.2rem;
    overflow: hidden;
	position: relative;
	border-radius: $border-radius $border-radius 0 0;
	@include media-breakpoint-up(lg){
		max-height: 15.4rem;

	}

}


.blog-item{
margin-bottom: 2rem;
@include media-breakpoint-up(md){
	margin-bottom: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
	flex-grow: 1;

}
}

.news-btn{
	display: flex;
	justify-content: center;

	& .btn{

		@include media-breakpoint-up(xl){
		margin-top: 2rem;

		}
		@include media-breakpoint-up(md){
			margin-top: 3rem;
			margin-bottom: 4rem;
		}
		@include media-breakpoint-up(lg){
			margin-bottom: 0rem;
		}
	}


}
.blog-content{
	background-color: $light;
	border-radius: 0 0 $border-radius $border-radius;
	padding: 2rem;
	box-shadow: 0px 5px 6px 1px rgba( 0,0,0,0.11);
	@include media-breakpoint-up(md){
		height: 100%;
	}
	@include media-breakpoint-up(lg){
		padding: 3rem 3rem 0 3rem;
		height: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}
	p{
		font-weight: 500;
		@include media-breakpoint-up(xl){
			font-weight: 600;
		}
	}
	.btn-cont{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 3rem auto 2rem auto;
		@include media-breakpoint-up(xl){
			margin: 5rem auto 6rem auto;

		}
	}
	& .btn{
		display: inline-block;
		@media (max-width: 320px){
			padding: 2rem 3rem;
		}
	}
}

.news-btn{
	margin-top: 2rem;
	& .btn{
		@media (max-width: 320px){
			padding: 2rem 3rem;
		}
	}
}
#content-after{
	header.section__heading{
		margin-top: 0;
		h2{
			margin-top: 0;
		}
	}
}
body > #content-after {
	@include media-breakpoint-down(lg){
		padding: 0rem 0px;
	}
}
