$primary: #00558E;
$secondary: #00b393;
$blue: #006CB5;
$danger: #dc3545;
$success: #28a745;
$light: #F8F8F8;
$white: #fff;
$black: #2D2D2D;


$grey: #dedede;
$menu-active: $blue;
