section#header {
	background: $light;
	font-size: 1.2rem;
	border-bottom: 7px solid $blue;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	width: 100%;
	@include media-breakpoint-up(xl) {
		display: none;
	}
	
}
