body.produkty-i-detale{
	#content{
		img{
			display: block;
			width:100%;
			transition: .3s ease;

		}

		.product{
			margin-bottom: 2rem;
			&__box{

				position: relative;
				overflow: hidden;
				&:hover{
					img{
						cursor: pointer;
						transform: scale(1.1);
					}
					&::before{
						opacity: 1;
					}
					&::after{
						transform: translateY(0px);
					}
				}
				&::before {
					content: url(../../../images/strona-glowna/fullsize-img.svg);
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					position: absolute;
					display: flex;
					align-items: center;
					justify-content: center;
					pointer-events: none;
					z-index: 2;
					opacity: 0;
					transition: .3s all;
				}
				&::after {
					content: "";
					display: flex;
					align-items: center;
					justify-content: center;
					pointer-events: none;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0,51,86,.7);
					z-index: 1;
					transform: translateY(100%);
					transition: .3s all;
				}

			}
			&__description{
				position: absolute;
				bottom:2rem;
				color: $white;
				font-size: 1.7rem;
				width:100%;
				padding-left: 6rem;
				z-index: 5;
				@media (min-width:1200px) and (max-width:1599px){
					font-size: 1.4rem;
					padding-left: 5rem;
				}
				@media (min-width:768px) and (max-width:991px){
					font-size: 1.3rem;
					padding-left: 5rem;
					padding-top: 5px;
				}
				@include media-breakpoint-down(sm){
					padding-top: 5px;
					font-size: 1.2rem;
				}
				@media (max-width:320px){
					font-size: 1.1rem;
					padding-left: 4rem;
				}
				&::before{
					content:'';
					position: absolute;
					width: calc(100% + 3rem);
					height: 250%;
					background-color: $primary;
					left:6%;
					bottom:-77%;
					z-index: -1;
					transform: skewX(-40deg);
					border-radius: 5px 0px 0px;
					@media (min-width:768px) and (max-width:991px){
						bottom:-95%;
					}
					@include media-breakpoint-down(sm){
						bottom: -100%;
					}
				}
			}
		}
	}
}
