@import '../utils/';
#copyright{
	background-image: url('../../../images/strona-glowna/copyright/copyright-background.png');
	background-repeat: no-repeat;
	background-size: cover;
	p {
		color: $white;
		padding: 2rem 3.6rem;
		font-weight: 500;
		margin: 0;
		@include media-breakpoint-up(lg){
			padding: 2rem 0;

		}
	}
	a{
		color: $white;
		font-weight: 500;
		@include media-breakpoint-up(lg){
			font-weight: 700;
		}
	}
}
