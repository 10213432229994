.btn{
    padding: 2rem 5rem;
    border: none;
    background-color: $white;
    outline: none;
    text-decoration: none;
    color: $black;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    transition: .3s;
	position: relative;

	@media screen and (min-width:1024px) and (max-width: 1279px){
		padding: 2rem 2rem;
	}
    & span{
        font-size: $p-fz;
        letter-spacing: 0.05em;
		margin: 0 auto;
		line-height: 1.7rem;
        @include media-breakpoint-up(xl){
            font-size: 1.5rem;
            transform: translateX(0);
            -webkit-transform: translateX(0);
			display: inline-block;
			line-height: 1.8rem;
			transition: all .3s;
			position: relative;
            &::after{
                content: url(../../../images/typografia/arrow-right.svg);
                position: absolute;
                right: 0;
				top: 2px;
                opacity: 0;
                transition: all .3s;
                transform: translateX(50%);
                -webkit-transform: translateX(50%);
            }
        }
    }

	@media screen and (min-width:1920px){
		padding: 2.6rem 6.5rem;
	}
    &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $blue;
        transition: .3s;
    }
    &:hover{
        &::after{
            @include media-breakpoint-up(xl){
           height: 7px;
            }
        }
        & span{
            transform: translateX(-7%);
            -webkit-transform: translateX(-7%);
            &::after{
                opacity: 1;
                transform: translateX(300%);
                -webkit-transform: translateX(300%);
            }
        }
    }
}
button.btn{
	span{
		&::after{
			bottom: 0px;
		}
	}
}
