.zespol{


.team-list{
	list-style: none;
	li{
		position: relative;
		margin-bottom: 1rem;
		&::before{
			content: url(../../../images/zespol/community.svg);
    position: absolute;
    left: -32px;
	top: 3px;
		}
	}
}
.team-pictures{
	img{
		width: 100%;
		height: auto;
		border-radius: 8px;
		cursor: pointer;
		&:first-of-type{
			margin-bottom: 2rem;
			display: block;
		}
	}
	margin-bottom: 3rem;
	margin-top: 2rem;
	@include media-breakpoint-up(xl){
		margin-bottom: 0;
		margin-top: 5px;
	}
}

}
