.grippers-intro{
	margin: 2rem 0 4rem 0;
	@include media-breakpoint-up(xl){
		margin: 0 0 8rem 0;
	}
	@media (min-width: 1600px){
		margin: 0 0 16rem 0;
	}
    & .section__heading{
        margin-top: 2rem;
    }
    @include media-breakpoint-up(lg){
        // padding-bottom: 6rem;
    }
    position: relative;
    p {
        @include media-breakpoint-up(xl){
            font-weight: 400;
        }
    }
    &__img{
        position: absolute;
        bottom: 0rem;
        right: 48rem;
		z-index: -1;
        @media screen and (max-width: 1366px){
            &:nth-of-type(3){
                display: none;
            }
        }
        &:last-of-type{
            top: 3rem;
            right: 0;
        }
        img {
            display: none;
            @include media-breakpoint-up(xl){
                display: block;
                border-radius: $border-radius;

            }
        }
    }

}
.hand-grippers {
	padding: 4rem 0;
	@include media-breakpoint-up(xl){
		padding: 8rem 0;
	}
	& .section__heading{
		margin-top: 0;
	}
}
.handgripper__element{
	&__before{
		@include media-breakpoint-down(lg){
			margin-bottom: 2rem;
		}
		p{
			@include media-breakpoint-up(md){
				margin-bottom: 3rem;
			}
			@include media-breakpoint-up(lg){
				font-weight: 500;
				margin-bottom: 4rem;
			}
		}
	}
	margin-bottom: 2rem;
	@include media-breakpoint-up(xl){
		margin-bottom: 4rem;
	}
	&:last-of-type{
		margin-bottom: 0;
	}
	&__pic{
		img{
			margin: 0 auto;
			display: block;
		}
		@include media-breakpoint-down(lg){
			margin: 0rem auto 2rem;
		}

	}
    &__info{

        @media screen and (min-width:1280px) and (max-width: 1599px){
            margin-left: 9rem;
        }
        h3 {
            margin: 0;
            margin-bottom: 2rem;
            font-size: 1.7rem;
            font-weight: 700;
            line-height: 2.1rem;
            letter-spacing: 0.05em;
			color: $primary;
			@include media-breakpoint-up(xl){
				font-size: 2.8rem;
				line-height: 3rem;
			}
		}
		& .spec{
			margin-bottom: 2rem;
			@include media-breakpoint-up(md){
				margin-bottom: 3rem;
			}
			@include media-breakpoint-up(lg){
				margin-bottom: 1rem;
			}
			p {
				font-size: 2rem;
			}
		}
        &__table{
			overflow-x: auto;
			@include media-breakpoint-up(lg){
				overflow: hidden;
			}


           & .table-item{
               margin-bottom: 2rem;
			   position: relative;
			   display: flex;
			   flex-wrap: nowrap;
			   &:last-of-type{
				margin-bottom: 0;
			}
			   @include media-breakpoint-up(md){
				 justify-content: space-between;

			   }
               &::after{
                   content: '';
				   display: block;
				   position: absolute;
                   width: 100%;
				   height: 1px;
				   top: 2rem;
                	margin-top: 1.5rem;
                   background-color: #DEDEDE;
               }
               &:last-of-type{
                   &::after{
                       display: none;
                   }
               }
               & .item{
				   font-weight: 500;
					line-height: 1.6rem;
					@include media-breakpoint-up(lg){
						line-height: 2.6rem;
					}
					span{
						font-size: 1.4rem;
						@include media-breakpoint-up(md){
							font-size: 1.5rem;
						}
					}
			   }
			   &--udzwig, &--waga{
				   p{
					   text-align: center;
					   margin-bottom: 1rem;
					   @include media-breakpoint-up(md){
						   text-align: left;

					   }
				   }
			   }
               &--model{
				   p{
					   text-align: center;
					   @include media-breakpoint-up(md){
						text-align: left;
					}
				   }
                   & .item{
                    span {
                        font-weight: 900;
                    }
                   }

               }
               & p {
                   font-weight: 900;
               }
           }
        }
    }
	& img{
		max-width: 100%;
	}
}
.grippers{
	padding: 4rem 0;
	@include media-breakpoint-up(xl){

		padding: 8rem 0;
	}
	& .section__heading{
		margin: 0;
	}
	& .pic{
		img{
			border-radius: 8px;
			max-width: 100%;
			@include media-breakpoint-down(md){
				margin: 0 auto;
				display: block;
			}
		}
	}


    & .table-item{

		margin-bottom: 2rem;
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		font-display: row;
		&:last-of-type{
			margin-bottom: 0;
		}
		@include media-breakpoint-up(lg){
		 justify-content: space-between;
		}

        p{
            font-weight: 900;
        }
        &--model{
            span{
                font-weight: 900;
            }
        }
        &::after{
            content: '';
			width:100%;
			position: absolute;
			height: 1px;
            margin-top: 1.5rem;
			background-color: #DEDEDE;
			bottom: 0;
			display: none;
			@include media-breakpoint-up(lg){
				top: 2rem;
				display: block;
			}
        }
        &:last-of-type{
            &::after{
                display: none;
            }
        }
        & .item{
			font-weight: 500;
			line-height: 1.6rem;
			@include media-breakpoint-up(md){
				line-height: 2.6rem;
			}
			span{
				font-size: 1.4rem;
				@include media-breakpoint-up(xl){
					font-size: 1.5rem;
				}
			}
		}

	}
	&__description{
		margin-top: 2rem;
		@include media-breakpoint-up(lg){
			margin-top: 0;
		}
		h3 {
			margin: 0;
            margin-bottom: 2rem;
            letter-spacing: 0.05em;
			color: $primary;
			font-size: 1.7rem;
			line-height: 2.1rem;
			font-weight: 700;
			@include media-breakpoint-up(xl){
				font-size: 2.8rem;
            	line-height: 3rem;
			}
		}
		p{
			@include media-breakpoint-up(xl){
				font-size: 2rem;
				margin-bottom: 1rem;
			}
		}
	}
	.responsive-table{
		display: block;
    width: 100%;
	overflow-x: auto;
	margin-top: 3rem;
	@include media-breakpoint-up(xl){
		margin-top: 3rem;
		overflow: hidden;
	}
	& .table-item{
		&--sec{
			&::after{
				bottom: 0;
				top: initial;

			}
		}

	}
	}
	&__before{
		margin-bottom: 3rem;
	}

}

.goog-te-spinner-animation{
	display: none;
}
.subpage{
	main{
		padding: 0;
	}
}
