.opinions{
	margin-top: 4rem;
	@include media-breakpoint-up(lg){
		margin-top: 8rem;
		padding-bottom: 2rem;
	}
	&.full-background::before{
		background-image: url('../../../images/strona-glowna/mowia-o-nas/mowia-o-nas-tlo-mob.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top;
		@include media-breakpoint-up(lg){
			background-image: url('../../../images/strona-glowna/mowia-o-nas/mowia-o-nas-tlo.jpg');
			background-attachment: fixed;
		}
	}
	.section__heading{
		h2{
			color: $white;
		}
		p{
			color: $blue;
		}
		&::after{
			background-color: $white;
		}
	}
	.btn-opinions{
		display: flex;
		justify-content: center;
		margin: 0 auto;
		& .btn{
			margin: 4rem 0;
			@include media-breakpoint-up(lg){
				margin: 6rem 0;
			}
		}
	}

	& .opinion__element:nth-of-type(1) {
		& .opinion{
			margin-top: 0;
		}
	}
	& .col-md-6:nth-of-type(3) {

			@include media-breakpoint-up(md){

				margin: 3rem auto 0;
			}
			@include media-breakpoint-up(lg){

				margin: 0rem auto 0;
			}

	}
}

.opinion{
	margin-top: 3rem;

	@include media-breakpoint-up(md){
		margin-top: 0;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		height: 100%;
	}
	&__author{
		width: 100%;
		background-color: $white;
		padding: 1.3rem 1.7rem;
		border-radius: $border-radius;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include media-breakpoint-up(lg){
			padding: 2rem;
		}
		& span{
			font-size: 1.4rem;
			font-weight: 500;
			letter-spacing: 0.07em;
			line-height: 2.1rem;
			color: $black;
			@include media-breakpoint-up(lg){
				font-size: 2rem;
				line-height: 2.6rem;
				font-weight: 400;
			}
		}
		& .stars{
			display: flex;
			.star{
				background-image: url('../../../images/strona-glowna/mowia-o-nas/star.svg');
				width: 11px;
				height: 11px;
				background-repeat: no-repeat;
				display: block;
				margin-left: 4px;
			}
		}
	}
	&__description{
		background-color: rgba(255,255,255, 0.13);
		padding: 2rem 2rem 3rem 2rem;
		margin-top: 1rem;
		flex-grow: 1;
		border-radius: $border-radius;
		@include media-breakpoint-up(lg){
			padding: 3rem;
			min-height: 25.6rem;
			height: auto;
		}
		& p{
			font-weight: 300 !important;
			font-size: 1.4rem;
			letter-spacing: 0.07em;
			line-height: 2.1rem;
			color: $white;
			margin: 0;
			font-style: italic;
			@include media-breakpoint-up(xl){
				font-size: 1.5rem;
				line-height: 3rem;
			}
		}
	}

}




